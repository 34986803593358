import axios from "axios";
import store from './store/global'

const API_URL = process.env.REACT_APP_API_ENDPOINT || ''
const $api = axios.create({
    withCredentials: true,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    baseURL: API_URL,
})
$api.interceptors.response.use()

class Api {
    async send(url, data = null, returnInitiate = false, method = 'get') {
        method = !method ? (!data ? 'get' : 'post') : method;
        const initiate = $api({method, url: '/api/' + url, data: data ? data : null})
        return returnInitiate ? initiate : initiate.then(response => response.data)
            .catch(error => {
                if (error.response.status === 401) {
                    store.setUser(null)
                    throw error.response.data.message
                } else {
                    throw error.response.data
                }
            })
    }

    async all(arr) {
        return await axios.all(arr).then(axios.spread((...responses) => responses.map(e => e.data)))
    }

    async delete(url) {
        return await this.send(url, null, false, 'delete');
    }

    async get(url, returnInitiate = false) {
        return await this.send(url, null, returnInitiate)
    }

    async post(url, data, returnInitiate = false) {
        return await this.send(url, data, returnInitiate, 'post');
    }

    async store(id, url, data){
        id = !id ? '' : id
        return await this.send(`${url}/${id}`, data, false, id ? 'put' : 'post');
    }

    async csrf() {
        return await $api.get('/sanctum/csrf-cookie')
    }

    async login(formData) {
        return await this.post('login', formData)
    }

    async log(object, action){
        return await this.post('log', {object, action})
    }

    logout() {
        return this.get('logout')
    }
}

export default new Api()
