import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router";
import Api from "../../Api";
import Loader from "../../components/Loader";
import NotFound from "../NotFound";
import AddUpdateFormUser from "./AddUpdateFormUser";
import Global from "../../store/global";
import {observer} from "mobx-react-lite";
import IconButton from "rsuite/IconButton";
import Trash from "@rsuite/icons/Trash";
import checkPermission from "../../hooks/checkPermission";
import Log from "./Log";

const User = () => {
    const {id} = useParams()
    const [data, setData] = useState(null)

    useEffect(() => {
        Api.get(`users/${id}`)
            .then(r => setData(r))
            .catch(() => setData(false))
    },[])

    const user = Global.getUser

    const canEdit = checkPermission('edit')

    let navigate = useNavigate()
    const handleDelete = () => {
        if (confirm("Do you really want to delete the user?")){
            Api.delete(`users/${id}`)
                .then(() => {
                    navigate(-1)
                })
        }
    }
    const callback = r => setData(r)

    if (data === null) return <Loader/>
    if (data === false) return <NotFound/>
    return (
        <div className="row">
            <div className="col-12 d-flex">
                <h2>{data.name}</h2>
                {(canEdit || user.id === data.id) &&
                    <AddUpdateFormUser {...data} callback={callback}/>
                }
                {
                    user.id !== data.id && data.id !== 1 && (
                        <IconButton
                            icon={<Trash color="red"/>}
                            size="sm" variant="outline-danger"
                            className="align-self-center ms-3"
                            onClick={handleDelete}
                        />
                    )
                }
            </div>
            <Log id={id}/>
        </div>
    );
};

export default observer(User);
