import React, {useState} from 'react';
import useAutoPaginate from "../hooks/useAutoPaginate";
import Table from "rsuite/Table";

const LogAction = () => {
    const [data, setData] = useState([])

    const [Element, load] = useAutoPaginate('logs', setData)
    return (
        <div className="col-12 mt-2">
            <h4 className="mb-2">Logs action</h4>
            <Table data={data} loading={load} autoHeight>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>User</Table.HeaderCell>
                    <Table.Cell>
                        {
                            rowData => rowData.user.name
                        }
                    </Table.Cell>
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Object</Table.HeaderCell>
                    <Table.Cell dataKey="object"/>
                </Table.Column>
                <Table.Column flexGrow={5}>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                    <Table.Cell dataKey="action"/>
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>When</Table.HeaderCell>
                    <Table.Cell dataKey="created_at"/>
                </Table.Column>
            </Table>
            <Element/>
        </div>
    );
};

export default LogAction;
