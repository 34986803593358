import React, {useCallback, useEffect, useRef, useState} from 'react';
import useScroll from "./useScroll";
import Api from "../Api";
import Loader from "../components/Loader";

const ObserverElement = React.forwardRef(({loading, isNextPage, futureLoading}, ref) => {
    let load = true
    if (!loading || !isNextPage) load = false
    if (futureLoading && isNextPage) load = true
    return (
        <div ref={isNextPage ? ref : null} className="py-1 text-center" style={{height: 40}}>
            {load ? <Loader /> : null}
        </div>
    )
})

const useAutoPaginate = (url, setData, setTotal = () => {}) => {
    const [info, setInfo] = useState({})
    const [defaultUrl, setDefaultUrl] = useState(url)
    const [page, setPage] = useState(1)
    const [load, setLoading] = useState(true)

    const ref = useRef()

    const fetchData = useCallback(() => {
        let symbol = url.includes('?') ? '&' : '?'
        Api.get(`${url}${symbol}page=${page}`)
            .then(json => {
                setInfo({...json, data: null})
                setData(prev => {
                    return page === 1 ? json.data : [...prev, ...json.data]
                })
                setTotal(json.total)
                setPage(page + 1)
                setLoading(false)
            })
    }, [page, url])

    useEffect(() => {
        if (defaultUrl !== url) {
            setData([])
            setDefaultUrl(url)
            setPage(1)
            setLoading(true)
        }
    }, [url, defaultUrl])

    const Element = ({loading = false, futureLoading = true}) => (
        <ObserverElement
            loading={loading}
            futureLoading={futureLoading && page > 1}
            ref={ref}
            isNextPage={!info.last_page || page < info.last_page + 1}
        />
    )

    useScroll(fetchData, ref, Element)

    return [Element, load]
};

export default useAutoPaginate;
