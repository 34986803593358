import {useCallback, useEffect, useState} from "react";
import Button from "rsuite/Button";

const ButtonSubmit = ({formRef = {}, ...props}) => {
    const [loading, setLoading] = useState(false)

    const click = useCallback((e) => {
        if (formRef && formRef.current){
            formRef.check = true
            if (formRef.current?.check())
                setLoading(true)
        }else {
            setLoading(true)
        }
        if (props.onClick)
            props.onClick(e)
    }, [formRef, props.onClick])

    useEffect(() => {
        if (formRef.check === false){
            setLoading(false)
        }
    },[formRef.check])

    return (
        <div>
            <Button
                type="submit"
                loading={loading}
                appearance="primary"
                {...props}
                onClick={click}
            >
                {props.children}
            </Button>
        </div>
    );
};
export default ButtonSubmit
