import React from 'react';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
    }
    static getDerivedStateFromError() {}

    componentDidCatch(error, info) {
        console.log(info)
    }

    render() {
        return this.props.children;
    }
}
