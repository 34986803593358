import {RouterProvider} from "react-router";
import Layout from "./Layout";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import {createBrowserRouter} from "react-router-dom";
import Orders from "../pages/Orders";
import Storages from "../pages/Storages";
import Users from "../pages/Users";
import User from "../pages/Users/User";
import Products from "../pages/Products";
import LogAction from "../pages/LogAction";

const routers = createBrowserRouter([
    {
        path: '/',
        element: <Layout/>,
        children: [
            {index: true, element: <Home/>},
            {path: "orders", element: <Orders/>},
            {path: "products", element: <Products/>},
            {path: "storages", element: <Storages/>},
            {
                path: "users",
                children: [
                    {index: true, element: <Users/>},
                    {path: ':id', element: <User/>}
                ]
            },
            {path: "logs", element: <LogAction/>},

            {path: '*', element: <NotFound/>}
        ]
    }
])
const Routers = () => {
    return (
        <RouterProvider
            router={routers}
        />
    );
};
export default Routers
