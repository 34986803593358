import React, {useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import Api from "../Api";
import Global from "../store/global";
import Form from "rsuite/Form";
import Schema from "rsuite/Schema";
import InputGroup from "rsuite/InputGroup";
import ButtonSubmit from '../components/form/ButtonSubmit'
import {autofocus} from "../utils/fn";


const model = Schema.Model({
    email: Schema.Types.StringType().isRequired("Введіть Email").isEmail('Не валідний Email'),
    password: Schema.Types.StringType().isRequired('Введіть пароль')
})
const Auth = (props) => {
    const [checkAuth, setCheckAuth] = useState(false)
    const [error, setError] = useState('')
    const [passType, setPassType] = useState('password')
    const formRef = useRef(0)
    const changePassType = () => {
        setPassType(r => r === 'password' ? 'text' : 'password')
    }

    useEffect(() => {
        Api.get('user')
            .then(user => {
                Global.setUser(user)
            })
            .catch(() => {
            })
            .finally(() => {
                setCheckAuth(true)
            })
    }, [])

    const user = Global.getUser

    useEffect(() => {
        if (formRef.current && checkAuth) {
            autofocus(formRef.current.root)
        }
    }, [formRef.current, checkAuth]);

    const submit = (check, d) => {
        if (!check) return;
        let data = new FormData(d.target)
        setError('')
        Api.csrf().then(() => {
            Api.login(data).then(r => {
                Global.setUser(r)
            }).catch(e => {
                console.log(e)
                setError(e)
                formRef.check = false
            })
        })
    }

    if (!checkAuth) {
        return null;
    }

    if (user) {
        return props.children
    }

    return (
        <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
            <div className="card py-3 px-4 shadow">
                <h2 className="text-center">Googevas</h2>
                <Form onSubmit={submit} fluid style={{width: 300}} model={model} ref={formRef}>
                    <Form.Group controlId="email">
                        <Form.ControlLabel>Email</Form.ControlLabel>
                        <Form.Control name="email"/>
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.ControlLabel>Password</Form.ControlLabel>
                        <InputGroup inside>
                            <Form.Control type={passType} name="password"/>
                            <InputGroup.Addon>
                                    <span onClick={changePassType} className="pointer">
                                        {passType === 'password'
                                            ? <i className="bi bi-eye-slash"></i>
                                            : <i className="bi bi-eye"></i>
                                        }
                                    </span>
                            </InputGroup.Addon>
                        </InputGroup>
                    </Form.Group>
                        {error
                            ? <div className="text-center form-text text-danger mb-3">{error}</div>
                            : null
                        }
                    <div className="text-center">
                        <ButtonSubmit formRef={formRef}>Log in</ButtonSubmit>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default observer(Auth);
