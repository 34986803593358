import React from 'react';
import {Link, NavLink} from "react-router-dom";
import Api from "../Api";
import Global from "../store/global";


const mobileTriggerMenuShow = () => {
    const elem = document.getElementById('navbarSupportedContent')
    if (elem.classList.contains('show')){
        elem.classList.remove('show')
        return;
    }
    elem.classList.add('show')
}

const classesLink = ({isActive}) => {
    return 'nav-link' + (isActive ? ' text-success': '')
}
const Header = () => {
    const logout = (e) => {
        e.preventDefault()
        Api.logout()
            .then(() => {
                Global.setUser(null)
                Global.setPermissions(null)
            })
    }
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
                <Link to={'/'} className="navbar-brand">Goodevas</Link>
                <button className="navbar-toggler" type="button" onClick={mobileTriggerMenuShow}>
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <NavLink to={'/orders'} className={classesLink}>Orders</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/storages'} className={classesLink}>Residues in warehouses</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/users'} className={classesLink}>Users</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/products'} className={classesLink}>Goods</NavLink>
                        </li>
                    </ul>
                    <div>
                        <a href="#" className="link" onClick={logout}>Logout</a>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header;
