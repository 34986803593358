import React, {useCallback, useState} from 'react';
import useAutoPaginate from "../../hooks/useAutoPaginate";
import {Link} from "react-router-dom";
import AddUpdateFormUser from "./AddUpdateFormUser";
import Table from "rsuite/Table";
import checkPermission from "../../hooks/checkPermission";
import Button from "rsuite/Button";
import {useNavigate} from "react-router";

const Users = () => {
    const [data, setData] = useState([])
    const callback = useCallback(r => {
        setData(p => [r, ...p])
    }, [])

    const navigate = useNavigate();

    const canAdd = checkPermission('add')

    const [Element, loading] = useAutoPaginate(`users`, setData)
    return (
        <div className="row mt-2">
            <div className="col-auto d-flex">
                <h3 className="me-2">Users</h3>
                {canAdd && <AddUpdateFormUser callback={callback}/>}
            </div>
            <div className="col"></div>
            <div className="col-auto d-flex align-items-center">
                <Button onClick={() => navigate('/logs')}>
                    Log actions
                </Button>
            </div>
            <div className="col-12 mt-2">
                <Table data={data} loading={loading} autoHeight affixHeader>
                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.Cell>{row => <Link to={`/users/${row.id}`}>{row.name}</Link>}</Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.Cell dataKey="email"/>
                    </Table.Column>
                </Table>
                <Element loading/>
            </div>
        </div>
    );
};

export default Users;
