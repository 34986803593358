import React, {useCallback, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import Global from "../store/global";

const CheckPermission = (action, service = null) => {
    const permissions = Global.getPermissions

    const [can] = useState(() => {
        if (permissions.includes('admin')){
            return true
        }
        // if (this.permissions.includes('viewer')){
        //     return false
        // }

        return false
    })

    return can
};

export default CheckPermission;
