import React, {useEffect, useRef, useState} from 'react';
import Modal from "rsuite/Modal";
import Form from "rsuite/Form";
import ButtonSubmit from "../../components/form/ButtonSubmit";

const CommentForm = ({comment, open, onClose, callback}) => {
    const [input, setInput] = useState(comment)

    useEffect(() => {
        setInput(comment)
    }, [comment]);

    const formRef = useRef()
    return (
        <Modal size="xs" open={open} onClose={onClose} overflow={false}>
            <Modal.Header>
                <Modal.Title>Change comment</Modal.Title>
                <Modal.Body>
                    <Form fluid ref={formRef} onSubmit={() => callback(input)}>
                        <Form.Control
                            className="mb-2"
                            name="input"
                            onChange={v => setInput(v)}
                            value={input}
                        />
                        <div className="d-flex justify-content-end">
                            <ButtonSubmit
                                formRef={formRef}
                            >
                                Update
                            </ButtonSubmit>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal.Header>
        </Modal>
    );
};

export default CommentForm;
