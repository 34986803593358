export const copyText = (element) => {
    let text = element instanceof Element ? element.textContent : element
    let copyText = decodeHTMLEntities(text.trim())
    navigator.clipboard.writeText(copyText);
}

export const autofocus = (elem) => {
    let input = elem.querySelector('input[type=text]')
    if (input) input.focus()
}

export const handleMouseHoverImg = (e) => {
    const ean = e.target.textContent
    let image = document.querySelector(`.image-${ean}`)
    if (!image){
        return;
    }
    const rect = e.target.getBoundingClientRect()
    const bottom = window.innerHeight - rect.top
    if (bottom < 350){
        image.style.top = 'auto'
        image.style.bottom = Math.round(bottom + 6) + 'px'
    }else {
        image.style.bottom = 'auto'
        image.style.top = Math.round(rect.bottom + 6) + 'px'
    }
    image.style.left = Math.round(rect.x + (rect.width / 2)) + 'px'
    image.style.display = "block"
}

export const handleMouseLeaveImg = (e) => {
    const ean = e.target.textContent
    let image = document.querySelector(`.image-${ean}`)
    if (!image){
        return;
    }
    image.style.display = "none"
}
