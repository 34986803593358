import React, {useEffect, useMemo, useRef, useState} from 'react';
import useAutoPaginate from "../hooks/useAutoPaginate";
import {MaterialReactTable} from "material-react-table";
import Button from "rsuite/Button";
import DateRangePicker from "rsuite/DateRangePicker";
import Tag from "rsuite/Tag";
import moment from "moment";
import InputGroup from "rsuite/InputGroup";
import {Input} from "rsuite";
import Search from "@rsuite/icons/Search";

const columns = [
    {
        header: 'Status', maxSize: 100, Cell: ({row}) => (
            <Tag className="fw-bold">{row.original.financial_status}</Tag>
        )
    },
    {header: 'Order', maxSize: 100, Cell: ({row}) => (<span className="fw-bold">{row.original.name}</span>)},
    {accessorKey: 'created_at', header: 'Date', maxSize: 100},
    {
        header: 'Customer', maxSize: 100, Cell: ({row}) => {
            return (
                <span>{row.original?.customer.first_name} {row.original?.customer.last_name}</span>
            )
        }
    },
    {
        header: 'Delivery type',
        Cell: ({row}) => (row.original.shipping_lines[0]?.code)
    },
    {
        header: 'Sum, $',
        maxSize: 100,
        Cell: ({row}) => (
            <span className="fw-bold">{row.original.total_price}</span>
        )
    },
    {
        header: 'Actions',
        maxSize: 100,
        Cell: ({row}) => (
            <>
                <Button size="xs" className="me-1" appearance="primary">Edit</Button>
                <Button size="xs" appearance="primary">Refund</Button>
            </>
        )
    },
]

const getLast7Days = () => [moment().subtract(7, 'days').toDate(), moment().toDate()]

const renderDetailPanel = ({row}) => {
    let discount = 0;
    return (
        <div className="d-flex">
            <div className="col-12">
                {row.original.line_items.map(e => {
                        const allocation = e.discount_allocations.reduce((sum, e) => sum + e.amount, 0)
                        discount += allocation
                        return (
                            <div className="d-flex mb-3 align-items-center" key={e.product_id}>
                                <div className="col-1 px-3"><img className="w-100" src={e.image} alt=""/></div>
                                <div className="col-5">{e.title} ({e.sku})</div>
                                <div className="col-2 px-1">{e.quantity} x {e.price_set.shop_money.amount}</div>
                                <div
                                    className="col-2">${e.price_set.shop_money.amount * e.quantity} {allocation ? `-$${allocation}` : null}</div>
                            </div>
                        )
                    }
                )}
                {discount ?
                    <div className="d-flex mb-3 align-items-center">
                        <div className="col-8 fw-bold"></div>
                        <div className="col-2 px-1 fw-bold">Discount:</div>
                        <div className="col-2">${discount}</div>
                    </div>
                    : null
                }
                <div className="d-flex mb-3 align-items-center">
                    <div className="col-8 fw-bold"></div>
                    <div className="col-2 px-1 fw-bold">Shipping:</div>
                    <div className="col-2">$0</div>
                </div>
                <div className="d-flex mb-3 align-items-center">
                    <div className="col-8 fw-bold"></div>
                    <div className="col-2 px-1 fw-bold">Total:</div>
                    <div className="col-2">${row.original.total_price}</div>
                </div>
            </div>
        </div>
    )
}

const Orders = () => {
    const [data, setData] = useState([])
    const [orderBy, setOrderBy] = useState('created_at')
    const [updated, setUpdated] = useState(0)
    const [dates, setDates] = useState(() => getLast7Days())
    const [total, setTotal] = useState(null)

    const [search, setSearch] = useState('')

    const searchRef = useRef()

    const url = `orders?orderBy=${orderBy}&up=${updated}&search=${search}`
        + `&dates=${moment(dates[0]).format('YYYY-MM-DD')}~${moment(dates[1]).format('YYYY-MM-DD')}`

    const [Element, load] = useAutoPaginate(url, setData, setTotal)

    console.log(search)
    return (
        <div className="row">
            <div className="col-12 d-flex mt-1 align-items-center">
                <h4>Orders {total ? `(${total})` : null}</h4>
                <div className="flex-fill"></div>
                <div className="pe-1">
                    <InputGroup size="sm">
                        <Input ref={searchRef} placeholder="Search"/>
                        <InputGroup.Button onClick={() => setSearch(searchRef.current.value)}>
                            <Search />
                        </InputGroup.Button>
                    </InputGroup>
                </div>
                <div className="pe-1">
                    <DateRangePicker
                        value={dates}
                        onChange={setDates}
                        size="sm"
                        placement="bottomEnd"
                        onClean={getLast7Days}
                    />
                </div>
                <div>
                    <Button
                        size="sm"
                        loading={load}
                        appearance="primary"
                        onClick={() => setUpdated(r => r + 1)}
                    >Update</Button>
                </div>
            </div>

            <div className="col-12 mt-3">
                <MaterialReactTable
                    columns={columns}
                    data={data}
                    enableColumnActions={false}
                    enablePagination={false}
                    enableTopToolbar={false}
                    enableDensityToggle={false}
                    enableBottomToolbar={false}
                    enableFullScreenToggle={false}
                    initialState={{
                        density: 'compact',
                        expanded: true,
                        showColumnFilters: false,
                        showColumnFilter: false,
                    }}
                    muiTableBodyProps={{
                        sx: (theme) => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: '#eee',
                            },
                        }),
                    }}
                    renderDetailPanel={renderDetailPanel}
                />
                <Element/>
            </div>
        </div>
    );
};

export default Orders;
