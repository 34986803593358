import React, {useEffect} from 'react';

const useScroll = (callback, ref, Element) => {

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) callback()
        })

        if (ref.current) observer.observe(ref.current);

        return () => {
            if (ref && ref.current)
                observer.unobserve(ref.current);
        }
    }, [callback, ref.current, Element]);
};

export default useScroll;