import React from 'react';
import Auth from "./components/Auth";
import ErrorBoundary from "./components/ErrorBoundary";
import Routers from "./components/Routers";

const App = () => {
    return (
        // <React.StrictMode>
        <Auth>
            <ErrorBoundary>
                <Routers/>
            </ErrorBoundary>
        </Auth>
        // </React.StrictMode>
    );
};

export default App;
