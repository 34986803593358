import React, {useMemo, useRef, useState} from 'react';
import Api from "../../Api";
import Schema from "rsuite/Schema";
import Modal from "rsuite/Modal";
import Form from "rsuite/Form";
import InputGroup from "rsuite/InputGroup";
import ButtonSubmit from "../../components/form/ButtonSubmit";
import IconButton from "rsuite/IconButton";
import Edit from "@rsuite/icons/Edit";
import Plus from "@rsuite/icons/Plus";
import SelectPicker from "rsuite/SelectPicker";
import Global from "../../store/global";
import checkPermission from "../../hooks/checkPermission";

const permissionsValues = [
    {value: 'admin', label: 'Administrator'},
    {value: 'viewer', label: 'View only'},
]

const AddUpdateFormUser = (
    {
        id = '',
        name = '',
        email = '',
        password = '',
        permissions = [],
        callback = () => {
        }
    }
) => {
    const [show, setShow] = useState(false)
    const [passType, setPassType] = useState('password')
    const [error, setError] = useState('')
    const [formValue, setFormValue] = useState({name, email, password, permission: permissions[0]?.permission})

    const user = Global.getUser

    const formRef = useRef()
    const submit = (e, d) => {
        if (!e) return;
        setError('')
        let data = new FormData(d.target)
        Api.store(id, 'users', data)
            .then(r => {
                callback(r)
                setShow(false)
            })
            .catch(e => {
                formRef.check = false
                setError(e.message)
            })
    }

    const model = useMemo(() => {
        const model = {
            name: Schema.Types.StringType().isRequired('This field is required.'),
            email: Schema.Types.StringType().isEmail('Please enter a valid email address.'),
            permission: Schema.Types.StringType().isRequired(),
        }
        if (!id) {
            model.password = Schema.Types.StringType().isRequired().minLength(6)
        }
        return Schema.Model(model)
    }, [])

    const changPassType = () => {
        setPassType(r => r === 'password' ? 'text' : 'password')
    }

    const canEdit = checkPermission('edit')

    return (
        <>
            <IconButton
                onClick={() => setShow(true)}
                size="sm"
                icon={id ? <Edit/> : <Plus/>}
                className="align-self-center ms-2"
                variant="outline-primary ms-2"
            />
            <Modal open={show} onClose={() => setShow(false)} size="xs" overflow={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{id ? 'Update' : 'Add'} user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form model={model} fluid onSubmit={submit} formValue={formValue} onChange={setFormValue}
                          ref={formRef}>
                        <input type="hidden" name="id" value={id}/>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.ControlLabel>Name</Form.ControlLabel>
                            <Form.Control type="text" name="name"/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.ControlLabel>Email</Form.ControlLabel>
                            <Form.Control type="email" name="email" disabled={!canEdit}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="password">
                            <Form.ControlLabel>Password</Form.ControlLabel>
                            <InputGroup inside>
                                <Form.Control type={passType} name="password"/>
                                <InputGroup.Addon>
                                    <span onClick={changPassType} className="pointer">
                                        {passType === 'password'
                                            ? <i className="bi bi-eye-slash"></i>
                                            : <i className="bi bi-eye"></i>
                                        }
                                    </span>
                                </InputGroup.Addon>
                            </InputGroup>
                        </Form.Group>
                        {user.id !== id && canEdit &&
                            <Form.Group controlId="permision">
                                <Form.ControlLabel>Permissions</Form.ControlLabel>
                                <input type="hidden" name="permissions[]" value={formValue.permission}/>
                                <Form.Control
                                    name="permission"
                                    accepter={SelectPicker}
                                    block
                                    data={permissionsValues}
                                    searchable={false}
                                    cleanable={false}
                                />
                            </Form.Group>
                        }
                        <div className="d-flex flex-wrap justify-content-end align-items-center">
                            <ButtonSubmit formRef={formRef}>
                                {!id ? 'Create' : 'Update'}
                            </ButtonSubmit>
                        </div>
                        {error && <div className="text-danger w-100 text-center mt-2">{error}</div>}
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddUpdateFormUser;
