import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Api from "../../Api";
import {MaterialReactTable} from "material-react-table";
import fileSaver from "file-saver/dist/FileSaver";
import Button from "rsuite/Button";
import checkPermission from "../../hooks/checkPermission";
import SelectPicker from "rsuite/SelectPicker";
import Close from "@rsuite/icons/Close";
import InputNumber from "rsuite/InputNumber";
import Dropdown from "rsuite/Dropdown";
import {Popover, Whisper} from "rsuite";
import CommentForm from "./CommentForm";
import IconButton from "rsuite/IconButton";
import Plus from "@rsuite/icons/Plus";
import { handleMouseHoverImg, handleMouseLeaveImg } from '../../utils/fn'


const stockCell = ({cell}) => {
    const value = cell.getValue()
    return value === null ? <Close size="xs"/> : value
}

const initialState = {
    density: 'compact',
    expanded: true,
    grouping: ['type'],
    showColumnFilters: false,
    showColumnFilter: false,
    columnVisibility: {sort_order: false},
    sorting: [{id: 'type', desc: false}, {id: 'sort_order', desc: false}],
    draggingColumn: true,
    columnOrder: [
        "mrt-row-drag",
        "ean",
        "name",
        "stock",
        "incoming",
        "production",
        "total",
        "sort_order",
        "type",
    ],
    columnPinning: {left: ['type', 'mrt-row-drag','ean', 'name']}
}

const firstColumnTwoDepthProps = {
    muiTableHeadCellProps: {
        align: 'center',
        sx: {
            borderLeft: '1px solid #eee',
            textAlign: 'center'
        },
    },
    muiTableBodyCellProps: {
        sx: {
            borderLeft: '1px solid #eee',
            textAlign: 'center',
            justifyContent: 'center'
        },
    },
    enableSorting: false,
    maxSize: 50,
}
const twoDepthProps = {
    muiTableHeadCellProps: {
        align: 'center'
    },
    muiTableBodyCellProps: {
        sx: {
            textAlign: 'center',
            justifyContent: 'center'
        },
    },
    enableSorting: false,
    maxSize: 50,
}

const maxSizeProp100 = {
    maxSize: 90
}

const groupColumnProps = {
    muiTableHeadCellProps: {
        sx: {
            borderLeft: '1px solid #eee',
        },
    }
}

const orderedColumnsWithNames = {us: 'US', germany: 'DE', uk: 'UK', canada: 'CA'}
const orderedColumnsWithNamesForTotal = { ...orderedColumnsWithNames, ua: "UA" }

const Storages = () => {
    const [data, setData] = useState([])

    const [loading, setLoading] = useState(false)
    const [updates, setUpdates] = useState(0)

    const [dates, setDates] = useState([])
    const [date, setDate] = useState(null)

    const [isExport, setIsExport] = useState(false)

    const [changedData, setChangedData] = useState({})
    const [incomingColumns, setIncomingColumns] = useState([])
    const [editingColumn, setEditingColumn] = useState('')

    const [productionColumns, setProductionColumns] = useState([])

    const [commentEditColumn, setCommentEditColumn] = useState('')
    const [comments, setComments] = useState(() => {
        let emptyComments = {}
        Object.keys(orderedColumnsWithNames).forEach(v => {
            let suffix = '';
            for (let i = 0; i < 5; i++) {
                suffix = i ? '_' + (i + 1) : ''
                emptyComments[`comment_${v}${suffix}`] = ''
            }
        })
        return emptyComments
    })

    const canEdit = checkPermission('edit')

    const handleClearColumn = useCallback((field) => {
        setDate(date => {
            setLoading(true)
            Api.get(`storages/clear?field=${field}&date=${date}`)
                .then(() => {
                    setUpdates(i => i + 1)
                })
            return date
        })
    }, [])

    const handleSaveData = useCallback(() => {
        setChangedData(incomingChange => {

            setEditingColumn(editIncomingColumn => {

                setData(data => {
                    setLoading(true)
                    const updateProducts = [],
                        newData = [...data.map(i => {
                            if (incomingChange[i.ean] !== undefined) {
                                i[editIncomingColumn] = parseInt(incomingChange[i.ean])
                                updateProducts.push(i)
                            }
                            return i
                        })]
                    Api.post(`storages?date=${date}`, updateProducts)
                        .then(() => {
                            Api.log(
                                'Residues',
                                `Changed incoming data for date ${date} and products: ${updateProducts.map(v => v.ean).join(', ')}`
                            )
                        })
                        .finally(() => setLoading(false))

                    return newData
                })

                return ''
            })

            return {}
        })
    }, [date])

    const HeadCell = useCallback(({title, k, columns, maxColumns = 5, functionAddColumn, commentKey, firstKey}) => {
        const ref = useRef(),
            isFirstKey = firstKey === k,
            lengthAdded = columns.filter(v => v.includes(firstKey.replace('_1', ''))).length
        return (
            <div>
                <Whisper
                    placement="bottom"
                    trigger="click"
                    ref={ref}
                    disabled={editingColumn !== k && !!editingColumn}
                    speaker={<Popover full>
                        <Dropdown.Menu size="xs" onClick={() => ref.current.close()}>
                            {editingColumn ?
                                <>
                                    <Dropdown.Item onClick={handleSaveData}>Save</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setEditingColumn('')}>Cancel</Dropdown.Item>
                                </>
                                :
                                <>
                                    <Dropdown.Item onClick={() => setEditingColumn(k)}>Edit</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setCommentEditColumn(commentKey)}>Comment</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleClearColumn(k)}>Clear</Dropdown.Item>
                                </>
                            }
                        </Dropdown.Menu>
                    </Popover>}
                >
                    <Button size="xs" disabled={editingColumn !== k && !!editingColumn}>{title}</Button>
                </Whisper>
                {
                    isFirstKey && lengthAdded < maxColumns
                        ? <IconButton
                            className="p-2px ms-1"
                            appearance="ghost"
                            color="blue"
                            size="xs"
                            disabled={!!editingColumn}
                            icon={<Plus width="10px" height="10px"/>}
                            onClick={() => functionAddColumn(firstKey.replace('_1', ''), lengthAdded + 1)}
                        />
                        : null
                }
                <small className="fw-light lh-1 white-space-normal d-block pt-2">{comments[commentKey]}</small>
            </div>
        )
    }, [editingColumn, comments, incomingColumns, productionColumns])

    const addIncomingColumn = useCallback((firstKey, numberColumn) => {
        setIncomingColumns(columns => {
            let searchValue = firstKey + ((numberColumn - 1) !== 1 ? '_' + (numberColumn - 1) : ''),
                indexLastColumn = columns.indexOf(searchValue)
            return [...columns.slice(0, indexLastColumn + 1), `${firstKey}_${numberColumn}`, ...columns.slice(indexLastColumn + 1)]
        })
    }, [])

    const addProductionColumn = useCallback((firstKey, numberColumn) => {
        setProductionColumns(columns => {
            let searchValue = firstKey + '_' + (numberColumn - 1),
                indexLastColumn = columns.indexOf(searchValue)
            return [...columns.slice(0, indexLastColumn + 1), `${firstKey}_${numberColumn}`, ...columns.slice(indexLastColumn + 1)]
        })
    }, [])

    const handleSubmitComment = useCallback((key, value, date) => {
        setComments(prev => ({...prev, [key]: value}))
        Api.post(`storages/comment?date=${date}`, {
            key, value
        }).then(() => {
            setCommentEditColumn('')
            Api.log('Residues', `Changed comment for ${key} column for date ${date}`)
        })
    }, [])

    const Cell = useCallback(({cell, row}) => {
        if (editingColumn && editingColumn === cell.column.id) {
            return <InputNumber
                scrollable={false}
                min={0}
                size="xs"
                value={changedData[row.original.ean] || cell.getValue() || 0}
                onChange={(e) => setChangedData(r => {
                    return {...r, [row.original.ean]: e}
                })}
            />
        }
        return cell.getValue() || 0
    }, [editingColumn, changedData])


    const columns = useMemo(() => [
        {accessorKey: 'ean', header: 'EAN', maxSize: 125, Cell: ({ cell }) => {
                return <span onMouseOver={handleMouseHoverImg} onMouseLeave={handleMouseLeaveImg}>{cell.getValue()}</span>
            }},
        {accessorKey: 'name', header: 'Name', maxSize: 300, size: 200, Cell: ({ cell, row }) => {
                if (row.original.link) {
                    return <a href={row.original.link} target="_blank" title={cell.getValue()}>{cell.getValue()}</a>
                }
                return <span title={cell.getValue()}>{cell.getValue()}</span>
            }},
        {
            header: 'In stock',
            accessorKey: 'stock',
            columns: Object.keys(orderedColumnsWithNames).map((v, i) => (
                {
                    accessorKey: v,
                    header: orderedColumnsWithNames[v],
                    maxSize: 60,
                    Cell: stockCell,
                    ...(!i ? firstColumnTwoDepthProps : twoDepthProps)
                }
            )),
            ...groupColumnProps
        },
        {
            header: 'Incoming',
            accessorKey: 'incoming',
            columns: incomingColumns.map((v, i) => {
                let key = v.replace(/_.*/, ''),
                    index = v.replace(/\D*/, "")
                return {
                    accessorKey: v,
                    Header: <HeadCell
                        title={`${orderedColumnsWithNames[key]}${index}`}
                        k={v}
                        columns={incomingColumns}
                        functionAddColumn={addIncomingColumn}
                        commentKey={`comment_${key}${index ? '_' + index : ''}`}
                        firstKey={`${key}_incoming`}
                    />,
                    Cell: Cell,
                    ...(!i ? firstColumnTwoDepthProps : twoDepthProps),
                    ...maxSizeProp100
                }
            }),
            ...groupColumnProps
        },
        {
            header: 'Production',
            accessorKey: 'production',
            columns: productionColumns.map((v, i) => {
                if (v === 'ua_production'){
                    return {
                        accessorKey: v,
                        Header: <HeadCell
                            title="UA"
                            k={v}
                            columns={productionColumns}
                            maxColumns={1}
                            functionAddColumn={addProductionColumn}
                            commentKey="comment_ua_production"
                            firstKey="ua_production"
                        />,
                        Cell: Cell,
                        ...(!i ? firstColumnTwoDepthProps : twoDepthProps),
                        ...maxSizeProp100
                    }
                }
                let key = v.replace(/_.*/, ''),
                    index = v.replace(/\D*/, "")
                return {
                    accessorKey: v,
                    Header: <HeadCell
                        title={`${orderedColumnsWithNames[key]}${index.replace('1', '')}`}
                        k={v}
                        columns={productionColumns}
                        maxColumns={3}
                        functionAddColumn={addProductionColumn}
                        commentKey={`comment_${key}_production_${index}`}
                        firstKey={`${key}_production_1`}
                    />,
                    Cell: Cell,
                    ...(!i ? firstColumnTwoDepthProps : twoDepthProps),
                    ...maxSizeProp100
                }
            }),
            ...groupColumnProps
        },
        {
            header: 'Total',
            accessorKey: 'total',
            columns: Object.keys(orderedColumnsWithNamesForTotal).map((v, i) => (
                {
                    accessorKey: `${v}_total`,
                    header: orderedColumnsWithNamesForTotal[v],
                    maxSize: 60,
                    Cell: ({row}) => {
                        let value = 0
                        Object.keys(row.original).forEach((key) => {
                            if (
                                !key.includes('comment')
                                && row.original[key]
                                && (key === v || key.includes(`${v}_incoming`) || key.includes(`${v}_production`))
                            ){
                                value = value + row.original[key]
                            }
                        })
                        return value
                    },
                    ...(!i ? firstColumnTwoDepthProps : twoDepthProps)
                }
            )),
            ...groupColumnProps
        },
        {accessorKey: 'sort_order', header: 'sort_order', enableHiding: true},
        {accessorKey: 'type', header: 'Type', enableEditing: false, size: 100},
    ], [editingColumn, changedData, comments, incomingColumns, productionColumns])

    useEffect(() => {
        setLoading(true)
        setDates([])
        setDate(null)
        Api.get('storages/dates')
            .then(r => {
                setDate(r[0])
                setDates(r)
            })
    }, [updates])

    useEffect(() => {
        if (date) {
            setLoading(true)
            setIncomingColumns([])
            setProductionColumns([])
            Api.get(`storages?date=${date}`)
                .then(data => {
                    setData(data)
                    const comments = {}
                    let incomingColumnsNew = [],
                        productionColumnsNew = []
                    Object.keys(orderedColumnsWithNames).forEach(k => {
                        let suffix = '',
                            productionSuffix = '',
                            incomingKey = '',
                            productionKey = '',
                            commentKey = '',
                            productionCommentKey = ''
                        for (let i = 0; i < 5; i++) {
                            suffix = i ? '_' + (i + 1) : ''
                            productionSuffix = '_' + (i + 1)
                            commentKey = `comment_${k}${suffix}`
                            productionCommentKey = `comment_${k}_production${productionSuffix}`
                            comments[commentKey] = data[0][commentKey] || ''
                            comments[productionCommentKey] = data[0][productionCommentKey] || ''
                            incomingKey = `${k}_incoming${suffix}`
                            productionKey = `${k}_production${productionSuffix}`
                            if (!i || data.some(v => !!v[incomingKey])) {
                                incomingColumnsNew.push(incomingKey)
                            }
                            if (!i || (i < 3 && data.some(v => !!v[productionKey]))) {
                                productionColumnsNew.push(productionKey)
                            }
                        }
                    })
                    comments['comment_ua_production'] = data[0]['comment_ua_production'] || ''
                    productionColumnsNew.push('ua_production')
                    setIncomingColumns(incomingColumnsNew)
                    setProductionColumns(productionColumnsNew)
                    setComments(comments)
                })
                .finally(() => setLoading(false))
        }
    }, [date]);


    const handleExport = useCallback(() => {
        setIsExport(true)
        Api.get(`storages/export?date=${date}`)
            .then(r => {
                const regex = /[^/]*$/gm;
                let m = regex.exec(r)
                fileSaver.saveAs(r, m[0]);
                setIsExport(false)
            })
    }, [date])

    const muiTableBodyRowDragHandleProps = useCallback(({table}) => ({
        onDragEnd: () => {
            const {draggingRow, hoveredRow} = table.getState();
            if (hoveredRow && draggingRow) {
                const hoverRow = hoveredRow._valuesCache,
                    dragRow = draggingRow._valuesCache;
                if (hoverRow.ean === dragRow.ean) {
                    return;
                }
                if (!hoveredRow.depth || !draggingRow.depth) {
                    alert('Переміщайте тільки товари')
                    return;
                }
                if (Math.trunc(hoverRow.sort_order / 100)
                    !== Math.trunc(dragRow.sort_order / 100)) {
                    alert('Переміщайте товар в рамках одного типу/категорії')
                    return;
                }
                setLoading(true)
                setData(data => {
                    const plus = hoverRow.sort_order < dragRow.sort_order,
                        startReSort = plus ? hoverRow.sort_order : dragRow.sort_order,
                        endReSort = !plus ? hoverRow.sort_order : dragRow.sort_order,
                        changeProducts = [],
                        newData = [...data.map(i => {
                            if (i.ean === dragRow.ean) {
                                i.sort_order = hoverRow.sort_order
                                changeProducts.push(i)
                                return i
                            }
                            if (plus && i.sort_order >= startReSort && i.sort_order < endReSort) {
                                i.sort_order++
                                changeProducts.push(i)
                            }
                            if (!plus && i.sort_order > startReSort && i.sort_order <= endReSort) {
                                i.sort_order--
                                changeProducts.push(i)
                            }
                            return i
                        })]
                    Api.post(`storages?date=${date}`, changeProducts)
                        .then(() => {
                            Api.log(
                                'Residues',
                                `Moved products for date ${date}: ${changeProducts.map(v => v.ean).join(', ')}`
                            )
                        })
                        .finally(() => setLoading(false))
                    return newData
                })
            }
        }
    }), [date])

    return (
        <div>
            <div className="d-flex align-items-center mt-1">
                <h4 className="me-3">Residues in warehouses</h4>
                <div className="flex-fill"></div>
                <SelectPicker
                    data={dates.map(i => {
                        return {value: i, label: i}
                    })}
                    loading={!!!dates.length}
                    value={date}
                    onChange={e => setDate(e)}
                    cleanable={false}
                    size="sm"
                    className="me-2"
                />
                <Button
                    appearance="primary"
                    size="sm"
                    className="me-2"
                    loading={loading}
                    onClick={() => setUpdates(p => p + 1)}
                >
                    Update
                </Button>
                <Button
                    size="sm"
                    loading={isExport}
                    className="btn btn-sm btn-primary"
                    onClick={handleExport}
                >
                    Export
                </Button>
            </div>
            <div className="col-12 mt-3">
                <MaterialReactTable
                    columns={columns}
                    data={data}
                    enableColumnActions={false}
                    enablePagination={false}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    groupedColumnMode="reorder"
                    enableSortingRemoval={false}
                    enableBottomToolbar={false}
                    enableStickyHeader={true}
                    enableColumnDragging={false}
                    initialState={initialState}
                    enableRowVirtualization
                    enableGlobalFilterRankedResults={false}
                    enableTopToolbar={false}
                    state={{isLoading: loading}}
                    enableRowOrdering={canEdit}
                    muiTableBodyRowDragHandleProps={muiTableBodyRowDragHandleProps}
                    // editingMode="cell"
                    // enableEditing
                    // onEditingRowSave={handleSaveRow}
                />
            </div>
            <CommentForm
                comment={comments[commentEditColumn] || ''}
                onClose={() => setCommentEditColumn('')}
                open={!!commentEditColumn}
                callback={v => handleSubmitComment(commentEditColumn, v, date)}
            />
            {
                data.map(e => e.image ? <img src={e.image} key={e.ean} className={`image-for-hover image-${e.ean}`} alt=""/> : null)
            }
        </div>
    );
};

export default Storages;
