import React from 'react';
import Button from "rsuite/Button";
import Modal from "rsuite/Modal";
import ButtonSubmit from "./form/ButtonSubmit";

const ModalDelete = ({open, onClose, callback, text}) => {
    return (
        <Modal open={open} onClose={onClose} backdrop="static" role="alertdialog" size="xs">
            <Modal.Body>{text}</Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
                <Button onClick={onClose} appearance="subtle">
                    Відмінити
                </Button>
                <ButtonSubmit onClick={callback} appearance="primary" color="red">
                    Видалити
                </ButtonSubmit>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalDelete;
