import React, {useCallback, useEffect, useState} from 'react';
import Api from "../../Api";
import {MaterialReactTable} from "material-react-table";
import AddEditProduct from "./AddEditProduct";
import IconButton from "rsuite/IconButton";
import Edit from "@rsuite/icons/Edit";
import Trash from "@rsuite/icons/Trash";
import ModalDelete from "../../components/ModalDelete";
import Button from "rsuite/Button";
import checkPermission from "../../hooks/checkPermission";
import Tag from "rsuite/Tag";
import { handleMouseHoverImg, handleMouseLeaveImg } from '../../utils/fn'


const columns = [
    {accessorKey: 'EAN', header: 'EAN', maxSize: 100, className: "test", Cell: ({ cell }) => {
            return <span onMouseOver={handleMouseHoverImg} onMouseLeave={handleMouseLeaveImg}>{cell.getValue()}</span>
        }},
    {accessorKey: 'sort_order', header: 'sort_order', enableHiding: true},
    {accessorKey: 'Name', header: 'Title', maxSize: 200, Cell: ({ cell, row }) => {
            if (row.original.link) {
                return <a href={row.original.link} target="_blank" title={cell.getValue()}>{cell.getValue()}</a>
            }
            return <span title={cell.getValue()}>{cell.getValue()}</span>
        }},
    {accessorKey: 'Artile', header: 'Article', maxSize: 200},
    {accessorKey: 'Type', header: 'Type', enableEditing: false, maxSize: 100},
    {accessorKey: 'variants', header: 'Variants', Cell: ({row}) => {
        return row.original.variants?.map(v => v.join(', '))
            .map((v,i) => <Tag size="sm" key={i}>{v}</Tag> )
        }},
]

const displayColumnDefOptions = {
    'mrt-row-actions': {
        muiTableHeadCellProps: {
            align: 'center',
        },
        size: 120,
    },
}

const initialState = {
    density: 'compact',
    expanded: true, //expand all groups by default
    grouping: ['Type'], //an array of columns to group by by default (can be multiple)
    showColumnFilters: false,
    showColumnFilter: false,
    columnVisibility: {sort_order: false},
    sorting: [{id: 'Type', desc: false}, {id: 'sort_order', desc: false}],
    showSkeletons: false,
}

const Products = () => {
    const [data, setData] = useState([])
    const [updates, setUpdates] = useState(0)
    const [loading, setLoading] = useState(false)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(null)
    const [openDeleteModal, setOpenDeleteModal] = useState(null)

    const canEdit = checkPermission('edit')

    useEffect(() => {
        setLoading(true)
        Api.get('products')
            .then(d => setData(d))
            .finally(() => setLoading(false))
    }, [updates])

    const handleDeleteProduct = useCallback((ean) => {
        Api.delete(`products/${ean}`)
            .then(() => {
                setData(r => r.filter(i => i.EAN !== ean))
                setOpenDeleteModal(null)
                Api.log('Product', `Deleted product ${ean}`)
            })
    }, [])

    const muiTableBodyRowDragHandleProps = useCallback(({table}) => ({
        onDragEnd: () => {
            const {draggingRow, hoveredRow} = table.getState();
            if (hoveredRow && draggingRow) {
                const hoverRow = hoveredRow._valuesCache,
                    dragRow = draggingRow._valuesCache;
                if (hoverRow.EAN === dragRow.EAN) {
                    return;
                }
                if (!hoveredRow.depth || !draggingRow.depth) {
                    alert('Drag only goods')
                    return;
                }
                if (Math.trunc(hoverRow.sort_order / 100)
                    !== Math.trunc(dragRow.sort_order / 100)) {
                    alert('Move the product within the same category type')
                    return;
                }
                setLoading(true)
                const plus = hoverRow.sort_order < dragRow.sort_order,
                    startReSort = plus ? hoverRow.sort_order : dragRow.sort_order,
                    endReSort = !plus ? hoverRow.sort_order : dragRow.sort_order,
                    changeProducts = [],
                    newData = [...data.map(i => {
                        if (i.EAN === dragRow.EAN) {
                            i.sort_order = hoverRow.sort_order
                            changeProducts.push(i)
                            return i
                        }
                        if (plus && i.sort_order >= startReSort && i.sort_order < endReSort) {
                            i.sort_order++
                            changeProducts.push(i)
                        }
                        if (!plus && i.sort_order > startReSort && i.sort_order <= endReSort) {
                            i.sort_order--
                            changeProducts.push(i)
                        }
                        return i
                    })]
                setData(newData)
                Api.post('products', changeProducts)
                    .then(() => {
                        Api.log('Product', `Moved product: ${changeProducts.map(v => v.EAN).join(', ')}`)
                    })
                    .finally(() => setLoading(false))
            }
        }
    }), [data])

    const renderRowActions = useCallback(({row}) => (
        <div className="flex">
            <IconButton
                icon={<Edit/>}
                size="sm"
                className="me-1"
                onClick={() => setOpenEditModal(row.original)}
            />
            <IconButton
                icon={<Trash color="red"/>}
                size="sm"
                color="red"
                onClick={() => setOpenDeleteModal(row.original.EAN)}
            />
        </div>
    ), [])

    return (
        <div>
            <div className="d-flex align-items-center mt-1">
                <h4 className="me-3">Товари ({data.length})</h4>
                {canEdit &&
                    <Button
                        appearance="primary"
                        size="sm"
                        onClick={() => setOpenAddModal(true)}
                    >
                        Add
                    </Button>
                }
                <div className="flex-fill"></div>
                <Button
                    appearance="primary"
                    size="sm"
                    loading={loading}
                    onClick={() => setUpdates(p => p + 1)}
                >
                    Update
                </Button>
            </div>


            {canEdit &&
                <AddEditProduct
                    add={!!openAddModal}
                    open={!!openAddModal || !!openEditModal}
                    onclose={() => {
                        setOpenAddModal(false)
                        setOpenEditModal(null)
                    }}
                    data={openEditModal ? openEditModal : {}}
                    products={data}
                    callback={(item, ean) => {
                        setData(r => !!ean ? [...r.filter(i => i.EAN !== ean), item] : [...r, item])
                    }}
                />
            }
            <div className="row justify-content-end">
                <div className="col-12 mt-3">
                    <MaterialReactTable
                        editingMode="modal"
                        displayColumnDefOptions={displayColumnDefOptions}
                        columns={columns}
                        data={data}
                        state={{isLoading: loading}}
                        enableEditing={canEdit}
                        enableStickyHeader
                        enableSortingRemoval={false}
                        enableColumnActions={false}
                        enablePagination={false}
                        enableGlobalFilterRankedResults={false}
                        enableDensityToggle={false}
                        enableFullScreenToggle={false}
                        groupedColumnMode="reorder"
                        enableBottomToolbar={false}
                        enableTopToolbar={false}
                        enableColumnDragging={false}
                        initialState={initialState}
                        enableRowOrdering={canEdit}
                        enableRowActions={canEdit}
                        renderRowActions={renderRowActions}
                        muiTableBodyRowDragHandleProps={muiTableBodyRowDragHandleProps}
                    />
                </div>
            </div>
            <ModalDelete
                text="Are you sure you want to remove the item?"
                open={!!openDeleteModal}
                onClose={() => setOpenDeleteModal(null)}
                callback={() => handleDeleteProduct(openDeleteModal)}
            />
            {
                data.map(e => e.image ? <img src={e.image} key={e.EAN} className={`image-for-hover image-${e.EAN}`} alt=""/> : null)
            }
        </div>
    );
};

export default Products;
