import React, {useState} from 'react';
import useAutoPaginate from "../../hooks/useAutoPaginate";
import Table from "rsuite/Table";

const Log = ({id}) => {
    const [data, setData] = useState([])

    const url = `users/${id}/logs`

    const [Element, load] = useAutoPaginate(url, setData)
    return (
        <div className="col-12">
            <h4>Logs action</h4>
            <Table data={data} loading={load} autoHeight>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Object</Table.HeaderCell>
                    <Table.Cell dataKey="object"/>
                </Table.Column>
                <Table.Column flexGrow={6}>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                    <Table.Cell dataKey="action"/>
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>When</Table.HeaderCell>
                    <Table.Cell dataKey="created_at"/>
                </Table.Column>
            </Table>
            <Element/>
        </div>
    );
};

export default Log;
