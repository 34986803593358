import React, {useEffect, useMemo, useRef, useState} from 'react';
import Modal from "rsuite/Modal";
import Form from "rsuite/Form";
import Schema from "rsuite/Schema";
import CheckPicker from "rsuite/CheckPicker";
import SelectPicker from "rsuite/SelectPicker";
import ButtonSubmit from "../../components/form/ButtonSubmit";
import Api from "../../Api";
import Button from "rsuite/Button";
import IconButton from "rsuite/IconButton";
import {ExpandOutline} from "@rsuite/icons";
import Trash from "@rsuite/icons/Trash";

const AddEditProduct = (
    {
        add = false,
        open = false,
        products = [],
        onclose = () => {},
        data = {},
        callback = (item, ean) => {}
    }
) => {

    const updateFormData = () => {
        return {
            Name: data.Name || '',
            Type: data.Type || '',
            Artile: data.Artile || '',
            variants: data.variants || [],
            EAN: data.EAN || '',
            image: data.image || '',
            link: data.link || '',
            sort_order: data.sort_order
        }
    }

    const model = useMemo(() => Schema.Model({
        EAN: Schema.Types.StringType().isRequired()
            .addRule(v => {
                return !products.map(i => i.EAN).filter(i => i !== data.EAN).includes(v)
            }, 'This EAN is already in use'),
        Name: Schema.Types.StringType().isRequired(),
        Type: Schema.Types.StringType().isRequired(),
        Artile: Schema.Types.StringType().isRequired(),
        variants: Schema.Types.ArrayType(),
        image: Schema.Types.StringType().isURL('Invalid url'),
        link: Schema.Types.StringType().isURL('Invalid url')
    }), [data, products])

    const [formData, setFormData] = useState(() => updateFormData())

    useEffect(() => {
        setFormData(updateFormData())
    }, [data])

    const formRef = useRef()
    const productsTypes = useMemo(() => {
        return [...new Set(products.map(item => item.Type))].map(
            item => ({ label: item, value: item })
        ).sort((a,b) => a.label > b.label ? 1 : (a.label < b.label ? -1 : 0))
    }, [products])

    const productsNames = useMemo(() => {
        return products.map(
            item => ({ label: `${item.Name} - ${item.EAN}`, value: item.EAN })
        ).sort((a,b) => a.label > b.label ? 1 : (a.label < b.label ? -1 : 0))
    }, [products])
    const handleSubmit = (c) => {
        if (!c) return;

        let sort_order = 0
        products.forEach(i => {
            if (i.Type === formData.Type && i.sort_order > sort_order){
                sort_order = i.sort_order
            }
        })
        formData.sort_order = sort_order + 1
        let newData = {...data || {}, ...formData}

        Api.post(`products`, [newData]).then(() => {
            callback(newData, data.EAN)
            onclose()
            Api.log('Product', `${add ? 'Added' : 'Updated'} product ${formData.EAN}`).then()
        })
    }

    return (
        <Modal open={open} onClose={onclose} size="xs" overflow={false}>
            <Modal.Header>
                <Modal.Title>{add ? 'Add' : 'Update'} product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form fluid model={model} ref={formRef} onSubmit={handleSubmit} onChange={setFormData} formValue={formData}>
                    <Form.Group controlId="EAN">
                        <Form.ControlLabel>EAN</Form.ControlLabel>
                        <Form.Control name="EAN"/>
                    </Form.Group>
                    <Form.Group controlId="Name">
                        <Form.ControlLabel>Name</Form.ControlLabel>
                        <Form.Control name="Name"/>
                    </Form.Group>
                    <Form.Group controlId="image">
                        <Form.ControlLabel>Image URL</Form.ControlLabel>
                        <Form.Control name="image"/>
                    </Form.Group>
                    <Form.Group controlId="image">
                        <Form.ControlLabel>Product URL</Form.ControlLabel>
                        <Form.Control name="link"/>
                    </Form.Group>
                    <Form.Group controlId="Artile">
                        <Form.ControlLabel>Article</Form.ControlLabel>
                        <Form.Control name="Artile"/>
                    </Form.Group>
                    <Form.Group controlId="Type">
                        <Form.ControlLabel>Type</Form.ControlLabel>
                        <Form.Control
                            name="Type"
                            accepter={SelectPicker}
                            block
                            placement="topStart"
                            data={productsTypes}
                        />
                    </Form.Group>
                    <div className="mb-3">
                        <label className="rs-form-control-label">
                            <span className="pe-2">Included goods</span>
                            <IconButton
                                size="xs"
                                icon={<ExpandOutline/>}
                                onClick={() => setFormData({...formData, variants: [...formData.variants, []]})}
                            />
                        </label>
                        {formData.variants.map((v, i) => (
                            <div key={i} className="mb-2 row flex-nowrap">
                                <div className="col-10 pe-0">
                                    <Form.Control
                                        name={`variants[${i}]`}
                                        accepter={CheckPicker}
                                        className="w-100"
                                        value={v}
                                        onChange={v => setFormData({...formData, variants: formData.variants.map((l,k)=> k === i ? v : l)})}
                                        placement="topStart"
                                        data={productsNames}
                                    />
                                </div>
                                <div className="col-2">
                                    <IconButton
                                        icon={<Trash color="red"/>}
                                        onClick={() => setFormData({...formData, variants: formData.variants.filter((l,k)=> k !== i)})}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="d-flex flex-wrap justify-content-end align-items-center">
                        <ButtonSubmit formRef={formRef}>
                            {add ? 'Add' : 'Update'}
                        </ButtonSubmit>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddEditProduct;
