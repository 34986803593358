import {makeAutoObservable} from "mobx";


class Global {
    user = null

    permissions = []

    constructor() {
        makeAutoObservable(this)
    }

    setUser(user) {
        this.user = user

        const permissionsWithUser = []
        if (user?.permissions){
            user.permissions?.forEach(i => {
                permissionsWithUser.push(i.permission)
            })
        }
        this.permissions = permissionsWithUser
    }

    get getUser() {
        return this.user
    }

    setPermissions (permissions){
        this.permissions = permissions
    }

    get getPermissions(){
        return this.permissions
    }
}

export default new Global()
